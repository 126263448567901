@import url('https://fonts.googleapis.com/css2?family=Inter:slnt,wght@-10..0,100..900&display=swap');

a {
    text-decoration: inherit !important;
    color: inherit !important;
}

body {
    background: #0D1117 !important;
    font-family: "Inter", sans-serif !important;

}

.container-fluid {
    font-family: "Inter", sans-serif !important;

}

.color-dark {
    background-color: #161B22 !important;
}

.color-lightdark {
    background-color: #1E252E !important;
}

.title {
    color: #ffffff;

}

.title h1 {
    font-size: 1.2rem;
}

.clickable {
    cursor: pointer;
}

.clickable:hover {
    filter: brightness(1.25);
}

.clickable2 {
    cursor: pointer;
    color: red !important;
}

.clickable2:hover {
    background: rgb(29, 34, 41);
    color: #fff;
}

.clickable3 {
    cursor: pointer;
    color: rgba(255, 255, 255, 0.555) !important;
}

.clickable3:hover {
    border-radius: 5px;
    background: rgb(20, 24, 29);
    color: #fff;
}

.clickable:active {
    filter: brightness(1.10);
}


.blue-animation {
    background: #58a6ff;
    box-shadow: 0 0 0 0 #47c1f1;
    animation: pulse-blue 2s infinite;
}

.red-animation {
    background: rgba(219, 16, 27, 1);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
}

@keyframes pulse-blue {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 #47c1f1;
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
}


.primary-animation {
    background: rgb(13, 110, 253);
    box-shadow: 0 0 0 0 rgb(24, 116, 246);
    animation: pulse-primary 2s infinite;
}

.success-animation {
    background: rgb(25, 135, 84);
    box-shadow: 0 0 0 0 rgb(57, 151, 23);
    animation: pulse-success 2s infinite;
}

@keyframes pulse-primary {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgb(59, 139, 255);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
}

@keyframes pulse-success {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgb(29, 120, 23);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
}

.dark-animation {
    background: rgb(22, 27, 34);
    box-shadow: 0 0 0 0 rgb(33, 40, 51);
    animation: pulse-dark 2s infinite;
}

@keyframes pulse-dark {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgb(44, 56, 68);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
}

.live-animation {
    background: rgb(255, 66, 86);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    animation: pulse-live 2s infinite;
}

@keyframes pulse-live {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
}

.green-animation {
    background: rgb(13, 110, 253);
    box-shadow: 0 0 0 0 rgb(24, 116, 246);
    animation: pulse-green 2s infinite;
}

@keyframes pulse-green {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgb(46, 204, 113);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
}

.text-truncate-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;

}

.text-truncate-3 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;

}

.text-truncate-3 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;

}

@media only screen and (max-width: 600px) {
    .eeep-text {
        background-color: #1a3362;
        color: #ffffff !important;
        font-family: Verdana !important;
        font-size: 6pt !important;
        line-height: 14pt;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .eeep-text {
        background-color: #1a3362;
        color: #ffffff !important;
        font-family: Verdana !important;
        font-size: 7pt !important;
        line-height: 14pt;
    }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .eeep-text {
        background-color: #1a3362;
        color: #ffffff !important;
        font-family: Verdana !important;
        font-size: 8pt !important;
        line-height: 14pt;
    }

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    .eeep-text {
        background-color: #1a3362;
        color: #ffffff !important;
        font-family: Verdana !important;
        font-size: 9.2pt !important;
        line-height: 14pt;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

    .eeep-text {
        background-color: #1a3362;
        color: #ffffff !important;
        font-family: Verdana !important;
        font-size: 9.2pt !important;
        line-height: 14pt;
    }

}