/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */
.scrollbar {
    scrollbar-gutter: stable
}

/* total width */
.scrollbar::-webkit-scrollbar {
    background-color: rgba(0,0,0,0);
    width: 0.5rem;
    height: 0.5rem;
    z-index: 999999;
}

/* background of the scrollbar except button or resizer */
.scrollbar::-webkit-scrollbar-track {
    background-color: rgba(0,0,0,0);
}

.scrollbar::-webkit-scrollbar-corner {
    background-color: transparent ;
}

/* scrollbar itself */
.scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,0);
    border-radius:16px;
    border:rgba(0,0,0,0);
}

/* set button(top and bottom of the scrollbar) */
.scrollbar::-webkit-scrollbar-button {
    display:none;
}


/* scrollbar when element is hovered */
.scrollbar:hover::-webkit-scrollbar-thumb {
    background-color: #a0a0a5;
    border:rgba(0,0,0,0);
}

/* scrollbar when scrollbar is hovered */
.scrollbar::-webkit-scrollbar-thumb:hover {
    background-color:#a0a0a5;
    border: rgba(0,0,0,0);
}
