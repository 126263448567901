@import "https://fonts.googleapis.com/css2?family=Inter:slnt,wght@-10..0,100..900&display=swap";
.scrollbar {
  scrollbar-gutter: stable;
}

.scrollbar::-webkit-scrollbar {
  z-index: 999999;
  background-color: #0000;
  width: .5rem;
  height: .5rem;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: #0000;
}

.scrollbar::-webkit-scrollbar-corner {
  background-color: #0000;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #0000;
  border: #0000;
  border-radius: 16px;
}

.scrollbar::-webkit-scrollbar-button {
  display: none;
}

.scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: #a0a0a5;
  border: #0000;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: #0000;
}

.flag-icon-background {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.flag-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 1.33333em;
  line-height: 1em;
  display: inline-block;
  position: relative;
}

.flag-icon:before {
  content: " ";
}

.flag-icon.flag-icon-squared {
  width: 1em;
}

.flag-icon-ad {
  background-image: url("ad.3412fbbb.svg");
}

.flag-icon-ad.flag-icon-squared {
  background-image: url("ad.358fb380.svg");
}

.flag-icon-ae {
  background-image: url("ae.9d65bb94.svg");
}

.flag-icon-ae.flag-icon-squared {
  background-image: url("ae.5013b4fb.svg");
}

.flag-icon-af {
  background-image: url("af.116723b4.svg");
}

.flag-icon-af.flag-icon-squared {
  background-image: url("af.c9b31c00.svg");
}

.flag-icon-ag {
  background-image: url("ag.783a1980.svg");
}

.flag-icon-ag.flag-icon-squared {
  background-image: url("ag.960b1120.svg");
}

.flag-icon-ai {
  background-image: url("ai.df65834d.svg");
}

.flag-icon-ai.flag-icon-squared {
  background-image: url("ai.96185211.svg");
}

.flag-icon-al {
  background-image: url("al.68c37e31.svg");
}

.flag-icon-al.flag-icon-squared {
  background-image: url("al.aad4f260.svg");
}

.flag-icon-am {
  background-image: url("am.5b89f760.svg");
}

.flag-icon-am.flag-icon-squared {
  background-image: url("am.6980378c.svg");
}

.flag-icon-ao {
  background-image: url("ao.1c4e654b.svg");
}

.flag-icon-ao.flag-icon-squared {
  background-image: url("ao.1afe7d5d.svg");
}

.flag-icon-aq {
  background-image: url("aq.a31b20d0.svg");
}

.flag-icon-aq.flag-icon-squared {
  background-image: url("aq.fedfd347.svg");
}

.flag-icon-ar {
  background-image: url("ar.8a75a2ce.svg");
}

.flag-icon-ar.flag-icon-squared {
  background-image: url("ar.46ab42e7.svg");
}

.flag-icon-as {
  background-image: url("as.ae367b5d.svg");
}

.flag-icon-as.flag-icon-squared {
  background-image: url("as.098987a2.svg");
}

.flag-icon-at {
  background-image: url("at.3c8945ef.svg");
}

.flag-icon-at.flag-icon-squared {
  background-image: url("at.e1029da4.svg");
}

.flag-icon-au {
  background-image: url("au.96f3e033.svg");
}

.flag-icon-au.flag-icon-squared {
  background-image: url("au.78736563.svg");
}

.flag-icon-aw {
  background-image: url("aw.5ad5ea3f.svg");
}

.flag-icon-aw.flag-icon-squared {
  background-image: url("aw.14db6044.svg");
}

.flag-icon-ax {
  background-image: url("ax.e103c402.svg");
}

.flag-icon-ax.flag-icon-squared {
  background-image: url("ax.53059eb3.svg");
}

.flag-icon-az {
  background-image: url("az.d900f458.svg");
}

.flag-icon-az.flag-icon-squared {
  background-image: url("az.eef6326c.svg");
}

.flag-icon-ba {
  background-image: url("ba.4a19f162.svg");
}

.flag-icon-ba.flag-icon-squared {
  background-image: url("ba.e6e5bc61.svg");
}

.flag-icon-bb {
  background-image: url("bb.cede0322.svg");
}

.flag-icon-bb.flag-icon-squared {
  background-image: url("bb.36bb6656.svg");
}

.flag-icon-bd {
  background-image: url("bd.f405a26e.svg");
}

.flag-icon-bd.flag-icon-squared {
  background-image: url("bd.a2150725.svg");
}

.flag-icon-be {
  background-image: url("be.2cf1a887.svg");
}

.flag-icon-be.flag-icon-squared {
  background-image: url("be.4c2a1e81.svg");
}

.flag-icon-bf {
  background-image: url("bf.82b9bd29.svg");
}

.flag-icon-bf.flag-icon-squared {
  background-image: url("bf.5ed468a2.svg");
}

.flag-icon-bg {
  background-image: url("bg.c727a017.svg");
}

.flag-icon-bg.flag-icon-squared {
  background-image: url("bg.9fd7e001.svg");
}

.flag-icon-bh {
  background-image: url("bh.8fdc10ef.svg");
}

.flag-icon-bh.flag-icon-squared {
  background-image: url("bh.0e795fa6.svg");
}

.flag-icon-bi {
  background-image: url("bi.fb28300a.svg");
}

.flag-icon-bi.flag-icon-squared {
  background-image: url("bi.6c12e58f.svg");
}

.flag-icon-bj {
  background-image: url("bj.6e1a1a3a.svg");
}

.flag-icon-bj.flag-icon-squared {
  background-image: url("bj.9eb15c0f.svg");
}

.flag-icon-bl {
  background-image: url("bl.3e6c5a6a.svg");
}

.flag-icon-bl.flag-icon-squared {
  background-image: url("bl.30c09e8a.svg");
}

.flag-icon-bm {
  background-image: url("bm.20c1b185.svg");
}

.flag-icon-bm.flag-icon-squared {
  background-image: url("bm.2c0eec6b.svg");
}

.flag-icon-bn {
  background-image: url("bn.bd74e3ae.svg");
}

.flag-icon-bn.flag-icon-squared {
  background-image: url("bn.fb52c35e.svg");
}

.flag-icon-bo {
  background-image: url("bo.6afd9971.svg");
}

.flag-icon-bo.flag-icon-squared {
  background-image: url("bo.5e36ff11.svg");
}

.flag-icon-bq {
  background-image: url("bq.36b70556.svg");
}

.flag-icon-bq.flag-icon-squared {
  background-image: url("bq.f1a2b6d2.svg");
}

.flag-icon-br {
  background-image: url("br.cc4c16eb.svg");
}

.flag-icon-br.flag-icon-squared {
  background-image: url("br.f0a3a91f.svg");
}

.flag-icon-bs {
  background-image: url("bs.9c7975d9.svg");
}

.flag-icon-bs.flag-icon-squared {
  background-image: url("bs.fde6caec.svg");
}

.flag-icon-bt {
  background-image: url("bt.2d9de226.svg");
}

.flag-icon-bt.flag-icon-squared {
  background-image: url("bt.fc926c9e.svg");
}

.flag-icon-bv {
  background-image: url("bv.50333a16.svg");
}

.flag-icon-bv.flag-icon-squared {
  background-image: url("bv.45130853.svg");
}

.flag-icon-bw {
  background-image: url("bw.7bff69b2.svg");
}

.flag-icon-bw.flag-icon-squared {
  background-image: url("bw.7ff6c974.svg");
}

.flag-icon-by {
  background-image: url("by.c1b2fad6.svg");
}

.flag-icon-by.flag-icon-squared {
  background-image: url("by.04ff6748.svg");
}

.flag-icon-bz {
  background-image: url("bz.43f3e0ca.svg");
}

.flag-icon-bz.flag-icon-squared {
  background-image: url("bz.02ab9276.svg");
}

.flag-icon-ca {
  background-image: url("ca.d55c27c4.svg");
}

.flag-icon-ca.flag-icon-squared {
  background-image: url("ca.ae6196ed.svg");
}

.flag-icon-cc {
  background-image: url("cc.ca4ddbf7.svg");
}

.flag-icon-cc.flag-icon-squared {
  background-image: url("cc.6de17845.svg");
}

.flag-icon-cd {
  background-image: url("cd.839eb222.svg");
}

.flag-icon-cd.flag-icon-squared {
  background-image: url("cd.a3a9de07.svg");
}

.flag-icon-cf {
  background-image: url("cf.7243d694.svg");
}

.flag-icon-cf.flag-icon-squared {
  background-image: url("cf.777c6e0e.svg");
}

.flag-icon-cg {
  background-image: url("cg.180bf23a.svg");
}

.flag-icon-cg.flag-icon-squared {
  background-image: url("cg.91759f75.svg");
}

.flag-icon-ch {
  background-image: url("ch.549a7ab3.svg");
}

.flag-icon-ch.flag-icon-squared {
  background-image: url("ch.50d4bbdd.svg");
}

.flag-icon-ci {
  background-image: url("ci.1ae8ed42.svg");
}

.flag-icon-ci.flag-icon-squared {
  background-image: url("ci.87548454.svg");
}

.flag-icon-ck {
  background-image: url("ck.7b7b8a09.svg");
}

.flag-icon-ck.flag-icon-squared {
  background-image: url("ck.bb080899.svg");
}

.flag-icon-cl {
  background-image: url("cl.22a61b3a.svg");
}

.flag-icon-cl.flag-icon-squared {
  background-image: url("cl.dfec159f.svg");
}

.flag-icon-cm {
  background-image: url("cm.0634a97f.svg");
}

.flag-icon-cm.flag-icon-squared {
  background-image: url("cm.1a881778.svg");
}

.flag-icon-cn {
  background-image: url("cn.0955781c.svg");
}

.flag-icon-cn.flag-icon-squared {
  background-image: url("cn.6f262632.svg");
}

.flag-icon-co {
  background-image: url("co.fbac3aac.svg");
}

.flag-icon-co.flag-icon-squared {
  background-image: url("co.97f623fb.svg");
}

.flag-icon-cr {
  background-image: url("cr.c62543c9.svg");
}

.flag-icon-cr.flag-icon-squared {
  background-image: url("cr.65bff005.svg");
}

.flag-icon-cu {
  background-image: url("cu.2d4f9178.svg");
}

.flag-icon-cu.flag-icon-squared {
  background-image: url("cu.1e4ce997.svg");
}

.flag-icon-cv {
  background-image: url("cv.7626dd16.svg");
}

.flag-icon-cv.flag-icon-squared {
  background-image: url("cv.4500d52c.svg");
}

.flag-icon-cw {
  background-image: url("cw.e45670e3.svg");
}

.flag-icon-cw.flag-icon-squared {
  background-image: url("cw.cfa310db.svg");
}

.flag-icon-cx {
  background-image: url("cx.671b35a2.svg");
}

.flag-icon-cx.flag-icon-squared {
  background-image: url("cx.1f2fcc3d.svg");
}

.flag-icon-cy {
  background-image: url("cy.0f9e551a.svg");
}

.flag-icon-cy.flag-icon-squared {
  background-image: url("cy.ed22a3fb.svg");
}

.flag-icon-cz {
  background-image: url("cz.2e7d3f08.svg");
}

.flag-icon-cz.flag-icon-squared {
  background-image: url("cz.921baf37.svg");
}

.flag-icon-de {
  background-image: url("de.ded4ffbe.svg");
}

.flag-icon-de.flag-icon-squared {
  background-image: url("de.4ba3ef65.svg");
}

.flag-icon-dj {
  background-image: url("dj.3cddc655.svg");
}

.flag-icon-dj.flag-icon-squared {
  background-image: url("dj.38fac9e4.svg");
}

.flag-icon-dk {
  background-image: url("dk.15eb7158.svg");
}

.flag-icon-dk.flag-icon-squared {
  background-image: url("dk.4d4275ca.svg");
}

.flag-icon-dm {
  background-image: url("dm.4f8ca625.svg");
}

.flag-icon-dm.flag-icon-squared {
  background-image: url("dm.94c58e09.svg");
}

.flag-icon-do {
  background-image: url("do.71cc9979.svg");
}

.flag-icon-do.flag-icon-squared {
  background-image: url("do.dd43207f.svg");
}

.flag-icon-dz {
  background-image: url("dz.d416afdb.svg");
}

.flag-icon-dz.flag-icon-squared {
  background-image: url("dz.ebcacc0f.svg");
}

.flag-icon-ec {
  background-image: url("ec.eb8c40b4.svg");
}

.flag-icon-ec.flag-icon-squared {
  background-image: url("ec.a7bade97.svg");
}

.flag-icon-ee {
  background-image: url("ee.fd515a98.svg");
}

.flag-icon-ee.flag-icon-squared {
  background-image: url("ee.a36c68ae.svg");
}

.flag-icon-eg {
  background-image: url("eg.0e2ff9e5.svg");
}

.flag-icon-eg.flag-icon-squared {
  background-image: url("eg.46945ed6.svg");
}

.flag-icon-eh {
  background-image: url("eh.73920a5c.svg");
}

.flag-icon-eh.flag-icon-squared {
  background-image: url("eh.f35dd246.svg");
}

.flag-icon-er {
  background-image: url("er.d2eba420.svg");
}

.flag-icon-er.flag-icon-squared {
  background-image: url("er.62b6ce72.svg");
}

.flag-icon-es {
  background-image: url("es.4842b681.svg");
}

.flag-icon-es.flag-icon-squared {
  background-image: url("es.91d24ded.svg");
}

.flag-icon-et {
  background-image: url("et.6ef76f80.svg");
}

.flag-icon-et.flag-icon-squared {
  background-image: url("et.6be0a190.svg");
}

.flag-icon-fi {
  background-image: url("fi.8b88c8a2.svg");
}

.flag-icon-fi.flag-icon-squared {
  background-image: url("fi.e4bc8017.svg");
}

.flag-icon-fj {
  background-image: url("fj.9e6191a3.svg");
}

.flag-icon-fj.flag-icon-squared {
  background-image: url("fj.61b2e85e.svg");
}

.flag-icon-fk {
  background-image: url("fk.30cf38ce.svg");
}

.flag-icon-fk.flag-icon-squared {
  background-image: url("fk.5502a492.svg");
}

.flag-icon-fm {
  background-image: url("fm.9d747660.svg");
}

.flag-icon-fm.flag-icon-squared {
  background-image: url("fm.af434622.svg");
}

.flag-icon-fo {
  background-image: url("fo.b6073c7c.svg");
}

.flag-icon-fo.flag-icon-squared {
  background-image: url("fo.ad676ba9.svg");
}

.flag-icon-fr {
  background-image: url("fr.d3ea888d.svg");
}

.flag-icon-fr.flag-icon-squared {
  background-image: url("fr.7fb3ad78.svg");
}

.flag-icon-ga {
  background-image: url("ga.deae9079.svg");
}

.flag-icon-ga.flag-icon-squared {
  background-image: url("ga.5798fbfb.svg");
}

.flag-icon-gb {
  background-image: url("gb.fbf15757.svg");
}

.flag-icon-gb.flag-icon-squared {
  background-image: url("gb.96e5c0ee.svg");
}

.flag-icon-gd {
  background-image: url("gd.c47d3b41.svg");
}

.flag-icon-gd.flag-icon-squared {
  background-image: url("gd.e658c8a5.svg");
}

.flag-icon-ge {
  background-image: url("ge.eef90685.svg");
}

.flag-icon-ge.flag-icon-squared {
  background-image: url("ge.7b41016c.svg");
}

.flag-icon-gf {
  background-image: url("gf.5fe073c3.svg");
}

.flag-icon-gf.flag-icon-squared {
  background-image: url("gf.d4165933.svg");
}

.flag-icon-gg {
  background-image: url("gg.56113d23.svg");
}

.flag-icon-gg.flag-icon-squared {
  background-image: url("gg.7b1d3386.svg");
}

.flag-icon-gh {
  background-image: url("gh.957add7e.svg");
}

.flag-icon-gh.flag-icon-squared {
  background-image: url("gh.a2dd4161.svg");
}

.flag-icon-gi {
  background-image: url("gi.965a762c.svg");
}

.flag-icon-gi.flag-icon-squared {
  background-image: url("gi.2f7737a7.svg");
}

.flag-icon-gl {
  background-image: url("gl.4edf510d.svg");
}

.flag-icon-gl.flag-icon-squared {
  background-image: url("gl.52b1a971.svg");
}

.flag-icon-gm {
  background-image: url("gm.ef6f8856.svg");
}

.flag-icon-gm.flag-icon-squared {
  background-image: url("gm.a3f8c56c.svg");
}

.flag-icon-gn {
  background-image: url("gn.856665af.svg");
}

.flag-icon-gn.flag-icon-squared {
  background-image: url("gn.53ec8061.svg");
}

.flag-icon-gp {
  background-image: url("gp.e9bf4aa9.svg");
}

.flag-icon-gp.flag-icon-squared {
  background-image: url("gp.e02e0617.svg");
}

.flag-icon-gq {
  background-image: url("gq.cc60be37.svg");
}

.flag-icon-gq.flag-icon-squared {
  background-image: url("gq.0873ab5c.svg");
}

.flag-icon-gr {
  background-image: url("gr.f838c3dc.svg");
}

.flag-icon-gr.flag-icon-squared {
  background-image: url("gr.9a448599.svg");
}

.flag-icon-gs {
  background-image: url("gs.18786507.svg");
}

.flag-icon-gs.flag-icon-squared {
  background-image: url("gs.d91b2601.svg");
}

.flag-icon-gt {
  background-image: url("gt.affd8863.svg");
}

.flag-icon-gt.flag-icon-squared {
  background-image: url("gt.56f1a8f1.svg");
}

.flag-icon-gu {
  background-image: url("gu.2c8b84a1.svg");
}

.flag-icon-gu.flag-icon-squared {
  background-image: url("gu.e7631d81.svg");
}

.flag-icon-gw {
  background-image: url("gw.d2b4733b.svg");
}

.flag-icon-gw.flag-icon-squared {
  background-image: url("gw.a68a29eb.svg");
}

.flag-icon-gy {
  background-image: url("gy.e64d7280.svg");
}

.flag-icon-gy.flag-icon-squared {
  background-image: url("gy.aaff7fd9.svg");
}

.flag-icon-hk {
  background-image: url("hk.b57090fa.svg");
}

.flag-icon-hk.flag-icon-squared {
  background-image: url("hk.c783bcd8.svg");
}

.flag-icon-hm {
  background-image: url("hm.a8888ffb.svg");
}

.flag-icon-hm.flag-icon-squared {
  background-image: url("hm.d083141d.svg");
}

.flag-icon-hn {
  background-image: url("hn.1b656863.svg");
}

.flag-icon-hn.flag-icon-squared {
  background-image: url("hn.8c91e43e.svg");
}

.flag-icon-hr {
  background-image: url("hr.41cfab92.svg");
}

.flag-icon-hr.flag-icon-squared {
  background-image: url("hr.0cb8baab.svg");
}

.flag-icon-ht {
  background-image: url("ht.08f784ab.svg");
}

.flag-icon-ht.flag-icon-squared {
  background-image: url("ht.30fe5ae9.svg");
}

.flag-icon-hu {
  background-image: url("hu.4ba93806.svg");
}

.flag-icon-hu.flag-icon-squared {
  background-image: url("hu.ba335ea4.svg");
}

.flag-icon-id {
  background-image: url("id.07bbbb83.svg");
}

.flag-icon-id.flag-icon-squared {
  background-image: url("id.cabaa356.svg");
}

.flag-icon-ie {
  background-image: url("ie.c538d923.svg");
}

.flag-icon-ie.flag-icon-squared {
  background-image: url("ie.8598fb62.svg");
}

.flag-icon-il {
  background-image: url("il.11245a24.svg");
}

.flag-icon-il.flag-icon-squared {
  background-image: url("il.d5d5d99d.svg");
}

.flag-icon-im {
  background-image: url("im.70dd8be0.svg");
}

.flag-icon-im.flag-icon-squared {
  background-image: url("im.b9a6701e.svg");
}

.flag-icon-in {
  background-image: url("in.d6b07436.svg");
}

.flag-icon-in.flag-icon-squared {
  background-image: url("in.ac3b58bd.svg");
}

.flag-icon-io {
  background-image: url("io.4011d212.svg");
}

.flag-icon-io.flag-icon-squared {
  background-image: url("io.7392df75.svg");
}

.flag-icon-iq {
  background-image: url("iq.e06914d5.svg");
}

.flag-icon-iq.flag-icon-squared {
  background-image: url("iq.826d0869.svg");
}

.flag-icon-ir {
  background-image: url("ir.a3b98326.svg");
}

.flag-icon-ir.flag-icon-squared {
  background-image: url("ir.0d7c8523.svg");
}

.flag-icon-is {
  background-image: url("is.5fab5d7a.svg");
}

.flag-icon-is.flag-icon-squared {
  background-image: url("is.c84aa2b0.svg");
}

.flag-icon-it {
  background-image: url("it.e19fd18a.svg");
}

.flag-icon-it.flag-icon-squared {
  background-image: url("it.da051fee.svg");
}

.flag-icon-je {
  background-image: url("je.ee0ca730.svg");
}

.flag-icon-je.flag-icon-squared {
  background-image: url("je.90e2884f.svg");
}

.flag-icon-jm {
  background-image: url("jm.bd51a18e.svg");
}

.flag-icon-jm.flag-icon-squared {
  background-image: url("jm.d70ae914.svg");
}

.flag-icon-jo {
  background-image: url("jo.7c646d80.svg");
}

.flag-icon-jo.flag-icon-squared {
  background-image: url("jo.3b2d7198.svg");
}

.flag-icon-jp {
  background-image: url("jp.37ef6245.svg");
}

.flag-icon-jp.flag-icon-squared {
  background-image: url("jp.a7444826.svg");
}

.flag-icon-ke {
  background-image: url("ke.3678c278.svg");
}

.flag-icon-ke.flag-icon-squared {
  background-image: url("ke.88ae1ba5.svg");
}

.flag-icon-kg {
  background-image: url("kg.c710bf05.svg");
}

.flag-icon-kg.flag-icon-squared {
  background-image: url("kg.e981049e.svg");
}

.flag-icon-kh {
  background-image: url("kh.85f3e00f.svg");
}

.flag-icon-kh.flag-icon-squared {
  background-image: url("kh.10fa75bb.svg");
}

.flag-icon-ki {
  background-image: url("ki.dc2a65bc.svg");
}

.flag-icon-ki.flag-icon-squared {
  background-image: url("ki.8308f710.svg");
}

.flag-icon-km {
  background-image: url("km.0c0c78c9.svg");
}

.flag-icon-km.flag-icon-squared {
  background-image: url("km.8af1fdf8.svg");
}

.flag-icon-kn {
  background-image: url("kn.d36e093f.svg");
}

.flag-icon-kn.flag-icon-squared {
  background-image: url("kn.8a61be47.svg");
}

.flag-icon-kp {
  background-image: url("kp.81a23bdb.svg");
}

.flag-icon-kp.flag-icon-squared {
  background-image: url("kp.320c0835.svg");
}

.flag-icon-kr {
  background-image: url("kr.ca4e8ce7.svg");
}

.flag-icon-kr.flag-icon-squared {
  background-image: url("kr.a882bc7c.svg");
}

.flag-icon-kw {
  background-image: url("kw.a2b8483e.svg");
}

.flag-icon-kw.flag-icon-squared {
  background-image: url("kw.a5e41d59.svg");
}

.flag-icon-ky {
  background-image: url("ky.6246f294.svg");
}

.flag-icon-ky.flag-icon-squared {
  background-image: url("ky.ad51c7d4.svg");
}

.flag-icon-kz {
  background-image: url("kz.7c21dcc0.svg");
}

.flag-icon-kz.flag-icon-squared {
  background-image: url("kz.8958f000.svg");
}

.flag-icon-la {
  background-image: url("la.5cea569c.svg");
}

.flag-icon-la.flag-icon-squared {
  background-image: url("la.44232a4b.svg");
}

.flag-icon-lb {
  background-image: url("lb.e797c90a.svg");
}

.flag-icon-lb.flag-icon-squared {
  background-image: url("lb.799a86fe.svg");
}

.flag-icon-lc {
  background-image: url("lc.d0deff2b.svg");
}

.flag-icon-lc.flag-icon-squared {
  background-image: url("lc.00b64997.svg");
}

.flag-icon-li {
  background-image: url("li.beec4876.svg");
}

.flag-icon-li.flag-icon-squared {
  background-image: url("li.5df5f097.svg");
}

.flag-icon-lk {
  background-image: url("lk.f2f66e94.svg");
}

.flag-icon-lk.flag-icon-squared {
  background-image: url("lk.e143eb6c.svg");
}

.flag-icon-lr {
  background-image: url("lr.7a9b2245.svg");
}

.flag-icon-lr.flag-icon-squared {
  background-image: url("lr.2329a996.svg");
}

.flag-icon-ls {
  background-image: url("ls.816695d7.svg");
}

.flag-icon-ls.flag-icon-squared {
  background-image: url("ls.ff071e67.svg");
}

.flag-icon-lt {
  background-image: url("lt.9b32e10c.svg");
}

.flag-icon-lt.flag-icon-squared {
  background-image: url("lt.30336cdf.svg");
}

.flag-icon-lu {
  background-image: url("lu.2d6771b4.svg");
}

.flag-icon-lu.flag-icon-squared {
  background-image: url("lu.a9746579.svg");
}

.flag-icon-lv {
  background-image: url("lv.40210d40.svg");
}

.flag-icon-lv.flag-icon-squared {
  background-image: url("lv.be8a15b9.svg");
}

.flag-icon-ly {
  background-image: url("ly.44b21e1b.svg");
}

.flag-icon-ly.flag-icon-squared {
  background-image: url("ly.79e127d3.svg");
}

.flag-icon-ma {
  background-image: url("ma.0dc188fe.svg");
}

.flag-icon-ma.flag-icon-squared {
  background-image: url("ma.fa53b99e.svg");
}

.flag-icon-mc {
  background-image: url("mc.c3c5e4ed.svg");
}

.flag-icon-mc.flag-icon-squared {
  background-image: url("mc.2a9a547c.svg");
}

.flag-icon-md {
  background-image: url("md.c9cdfe4c.svg");
}

.flag-icon-md.flag-icon-squared {
  background-image: url("md.bbe7278b.svg");
}

.flag-icon-me {
  background-image: url("me.602979d0.svg");
}

.flag-icon-me.flag-icon-squared {
  background-image: url("me.42028fa1.svg");
}

.flag-icon-mf {
  background-image: url("mf.ad9e9db8.svg");
}

.flag-icon-mf.flag-icon-squared {
  background-image: url("mf.601fa8cf.svg");
}

.flag-icon-mg {
  background-image: url("mg.cb488b65.svg");
}

.flag-icon-mg.flag-icon-squared {
  background-image: url("mg.00970317.svg");
}

.flag-icon-mh {
  background-image: url("mh.f7969c6d.svg");
}

.flag-icon-mh.flag-icon-squared {
  background-image: url("mh.9b582ce9.svg");
}

.flag-icon-mk {
  background-image: url("mk.ce7aece3.svg");
}

.flag-icon-mk.flag-icon-squared {
  background-image: url("mk.d260f02a.svg");
}

.flag-icon-ml {
  background-image: url("ml.5b1e2282.svg");
}

.flag-icon-ml.flag-icon-squared {
  background-image: url("ml.45ddc42c.svg");
}

.flag-icon-mm {
  background-image: url("mm.f862df68.svg");
}

.flag-icon-mm.flag-icon-squared {
  background-image: url("mm.a777741b.svg");
}

.flag-icon-mn {
  background-image: url("mn.61633688.svg");
}

.flag-icon-mn.flag-icon-squared {
  background-image: url("mn.7fe859b7.svg");
}

.flag-icon-mo {
  background-image: url("mo.f8fdb5f5.svg");
}

.flag-icon-mo.flag-icon-squared {
  background-image: url("mo.9990a7b2.svg");
}

.flag-icon-mp {
  background-image: url("mp.05db4759.svg");
}

.flag-icon-mp.flag-icon-squared {
  background-image: url("mp.8a3788d7.svg");
}

.flag-icon-mq {
  background-image: url("mq.8dd68d31.svg");
}

.flag-icon-mq.flag-icon-squared {
  background-image: url("mq.f3e552cb.svg");
}

.flag-icon-mr {
  background-image: url("mr.2eec3d38.svg");
}

.flag-icon-mr.flag-icon-squared {
  background-image: url("mr.a15204ca.svg");
}

.flag-icon-ms {
  background-image: url("ms.f04a2503.svg");
}

.flag-icon-ms.flag-icon-squared {
  background-image: url("ms.7fd261b9.svg");
}

.flag-icon-mt {
  background-image: url("mt.28846c51.svg");
}

.flag-icon-mt.flag-icon-squared {
  background-image: url("mt.9b3f3d62.svg");
}

.flag-icon-mu {
  background-image: url("mu.eb37d900.svg");
}

.flag-icon-mu.flag-icon-squared {
  background-image: url("mu.e40cd856.svg");
}

.flag-icon-mv {
  background-image: url("mv.3746163c.svg");
}

.flag-icon-mv.flag-icon-squared {
  background-image: url("mv.347f48c0.svg");
}

.flag-icon-mw {
  background-image: url("mw.4b3a204d.svg");
}

.flag-icon-mw.flag-icon-squared {
  background-image: url("mw.c48d5103.svg");
}

.flag-icon-mx {
  background-image: url("mx.32182cfa.svg");
}

.flag-icon-mx.flag-icon-squared {
  background-image: url("mx.15c68d2a.svg");
}

.flag-icon-my {
  background-image: url("my.b2fe64b6.svg");
}

.flag-icon-my.flag-icon-squared {
  background-image: url("my.e27fed84.svg");
}

.flag-icon-mz {
  background-image: url("mz.b1627183.svg");
}

.flag-icon-mz.flag-icon-squared {
  background-image: url("mz.35bfdf16.svg");
}

.flag-icon-na {
  background-image: url("na.5037a68f.svg");
}

.flag-icon-na.flag-icon-squared {
  background-image: url("na.af9e2e80.svg");
}

.flag-icon-nc {
  background-image: url("nc.90d55c61.svg");
}

.flag-icon-nc.flag-icon-squared {
  background-image: url("nc.44e181e0.svg");
}

.flag-icon-ne {
  background-image: url("ne.e46d353c.svg");
}

.flag-icon-ne.flag-icon-squared {
  background-image: url("ne.0d354ba3.svg");
}

.flag-icon-nf {
  background-image: url("nf.ee25720c.svg");
}

.flag-icon-nf.flag-icon-squared {
  background-image: url("nf.297a9e38.svg");
}

.flag-icon-ng {
  background-image: url("ng.37acba91.svg");
}

.flag-icon-ng.flag-icon-squared {
  background-image: url("ng.73d50504.svg");
}

.flag-icon-ni {
  background-image: url("ni.9981a256.svg");
}

.flag-icon-ni.flag-icon-squared {
  background-image: url("ni.d929f498.svg");
}

.flag-icon-nl {
  background-image: url("nl.928cc1f2.svg");
}

.flag-icon-nl.flag-icon-squared {
  background-image: url("nl.2638c556.svg");
}

.flag-icon-no {
  background-image: url("no.e15f84cb.svg");
}

.flag-icon-no.flag-icon-squared {
  background-image: url("no.8b1634f5.svg");
}

.flag-icon-np {
  background-image: url("np.62988bd3.svg");
}

.flag-icon-np.flag-icon-squared {
  background-image: url("np.47ae2cd5.svg");
}

.flag-icon-nr {
  background-image: url("nr.53fa3658.svg");
}

.flag-icon-nr.flag-icon-squared {
  background-image: url("nr.6f875cbd.svg");
}

.flag-icon-nu {
  background-image: url("nu.6ff62000.svg");
}

.flag-icon-nu.flag-icon-squared {
  background-image: url("nu.e098c315.svg");
}

.flag-icon-nz {
  background-image: url("nz.95c1d304.svg");
}

.flag-icon-nz.flag-icon-squared {
  background-image: url("nz.f5c1eace.svg");
}

.flag-icon-om {
  background-image: url("om.f1248c21.svg");
}

.flag-icon-om.flag-icon-squared {
  background-image: url("om.3fa56eaf.svg");
}

.flag-icon-pa {
  background-image: url("pa.1b7f40ca.svg");
}

.flag-icon-pa.flag-icon-squared {
  background-image: url("pa.8f24c8a1.svg");
}

.flag-icon-pe {
  background-image: url("pe.72bf23e4.svg");
}

.flag-icon-pe.flag-icon-squared {
  background-image: url("pe.e2653541.svg");
}

.flag-icon-pf {
  background-image: url("pf.3621f605.svg");
}

.flag-icon-pf.flag-icon-squared {
  background-image: url("pf.0f99dff7.svg");
}

.flag-icon-pg {
  background-image: url("pg.be45a65c.svg");
}

.flag-icon-pg.flag-icon-squared {
  background-image: url("pg.f9fce644.svg");
}

.flag-icon-ph {
  background-image: url("ph.c74a8ccf.svg");
}

.flag-icon-ph.flag-icon-squared {
  background-image: url("ph.be8d156c.svg");
}

.flag-icon-pk {
  background-image: url("pk.fd5341fc.svg");
}

.flag-icon-pk.flag-icon-squared {
  background-image: url("pk.f5008cb3.svg");
}

.flag-icon-pl {
  background-image: url("pl.913d37fa.svg");
}

.flag-icon-pl.flag-icon-squared {
  background-image: url("pl.1853c3f7.svg");
}

.flag-icon-pm {
  background-image: url("pm.5f5ca9b2.svg");
}

.flag-icon-pm.flag-icon-squared {
  background-image: url("pm.cca17c3d.svg");
}

.flag-icon-pn {
  background-image: url("pn.d1c25ff6.svg");
}

.flag-icon-pn.flag-icon-squared {
  background-image: url("pn.bbc6344a.svg");
}

.flag-icon-pr {
  background-image: url("pr.c02a3fa7.svg");
}

.flag-icon-pr.flag-icon-squared {
  background-image: url("pr.fdf327d0.svg");
}

.flag-icon-ps {
  background-image: url("ps.909e685d.svg");
}

.flag-icon-ps.flag-icon-squared {
  background-image: url("ps.21b6438d.svg");
}

.flag-icon-pt {
  background-image: url("pt.18c3e20d.svg");
}

.flag-icon-pt.flag-icon-squared {
  background-image: url("pt.a66e4c0c.svg");
}

.flag-icon-pw {
  background-image: url("pw.ad3462be.svg");
}

.flag-icon-pw.flag-icon-squared {
  background-image: url("pw.afa2d212.svg");
}

.flag-icon-py {
  background-image: url("py.6e34d343.svg");
}

.flag-icon-py.flag-icon-squared {
  background-image: url("py.b7ef5645.svg");
}

.flag-icon-qa {
  background-image: url("qa.03d95d3a.svg");
}

.flag-icon-qa.flag-icon-squared {
  background-image: url("qa.26d27ea0.svg");
}

.flag-icon-re {
  background-image: url("re.05d9bb8c.svg");
}

.flag-icon-re.flag-icon-squared {
  background-image: url("re.ebbcb349.svg");
}

.flag-icon-ro {
  background-image: url("ro.623454e6.svg");
}

.flag-icon-ro.flag-icon-squared {
  background-image: url("ro.66f834ce.svg");
}

.flag-icon-rs {
  background-image: url("rs.9bc66cbc.svg");
}

.flag-icon-rs.flag-icon-squared {
  background-image: url("rs.db94e04c.svg");
}

.flag-icon-ru {
  background-image: url("ru.9174e2e7.svg");
}

.flag-icon-ru.flag-icon-squared {
  background-image: url("ru.d6b114a7.svg");
}

.flag-icon-rw {
  background-image: url("rw.b9c1c9c8.svg");
}

.flag-icon-rw.flag-icon-squared {
  background-image: url("rw.c2326779.svg");
}

.flag-icon-sa {
  background-image: url("sa.7e475214.svg");
}

.flag-icon-sa.flag-icon-squared {
  background-image: url("sa.176bafe0.svg");
}

.flag-icon-sb {
  background-image: url("sb.6152812a.svg");
}

.flag-icon-sb.flag-icon-squared {
  background-image: url("sb.b9ef121c.svg");
}

.flag-icon-sc {
  background-image: url("sc.bb4244a0.svg");
}

.flag-icon-sc.flag-icon-squared {
  background-image: url("sc.9ff6059f.svg");
}

.flag-icon-sd {
  background-image: url("sd.d02a7ff5.svg");
}

.flag-icon-sd.flag-icon-squared {
  background-image: url("sd.7b59ca4a.svg");
}

.flag-icon-se {
  background-image: url("se.07c68e4d.svg");
}

.flag-icon-se.flag-icon-squared {
  background-image: url("se.012ecd9e.svg");
}

.flag-icon-sg {
  background-image: url("sg.1de2969c.svg");
}

.flag-icon-sg.flag-icon-squared {
  background-image: url("sg.829eb7f8.svg");
}

.flag-icon-sh {
  background-image: url("sh.b8ea4846.svg");
}

.flag-icon-sh.flag-icon-squared {
  background-image: url("sh.af307ea9.svg");
}

.flag-icon-si {
  background-image: url("si.735596f4.svg");
}

.flag-icon-si.flag-icon-squared {
  background-image: url("si.d197bd56.svg");
}

.flag-icon-sj {
  background-image: url("sj.aac19753.svg");
}

.flag-icon-sj.flag-icon-squared {
  background-image: url("sj.3bcfe8e7.svg");
}

.flag-icon-sk {
  background-image: url("sk.7de9cf1c.svg");
}

.flag-icon-sk.flag-icon-squared {
  background-image: url("sk.5af046ad.svg");
}

.flag-icon-sl {
  background-image: url("sl.1517ed6d.svg");
}

.flag-icon-sl.flag-icon-squared {
  background-image: url("sl.f92d39b5.svg");
}

.flag-icon-sm {
  background-image: url("sm.fa65eb8a.svg");
}

.flag-icon-sm.flag-icon-squared {
  background-image: url("sm.044b5225.svg");
}

.flag-icon-sn {
  background-image: url("sn.bf911fa1.svg");
}

.flag-icon-sn.flag-icon-squared {
  background-image: url("sn.6dfc9f77.svg");
}

.flag-icon-so {
  background-image: url("so.fc4c2afb.svg");
}

.flag-icon-so.flag-icon-squared {
  background-image: url("so.1e18241a.svg");
}

.flag-icon-sr {
  background-image: url("sr.8f10d17d.svg");
}

.flag-icon-sr.flag-icon-squared {
  background-image: url("sr.3ddb89bc.svg");
}

.flag-icon-ss {
  background-image: url("ss.e21823f6.svg");
}

.flag-icon-ss.flag-icon-squared {
  background-image: url("ss.d76769ab.svg");
}

.flag-icon-st {
  background-image: url("st.fbd9cb1a.svg");
}

.flag-icon-st.flag-icon-squared {
  background-image: url("st.91f3e51c.svg");
}

.flag-icon-sv {
  background-image: url("sv.a3a7f19b.svg");
}

.flag-icon-sv.flag-icon-squared {
  background-image: url("sv.673197b1.svg");
}

.flag-icon-sx {
  background-image: url("sx.f555dd0f.svg");
}

.flag-icon-sx.flag-icon-squared {
  background-image: url("sx.d87db66b.svg");
}

.flag-icon-sy {
  background-image: url("sy.6dcb05e9.svg");
}

.flag-icon-sy.flag-icon-squared {
  background-image: url("sy.e3d54282.svg");
}

.flag-icon-sz {
  background-image: url("sz.5b92aa0b.svg");
}

.flag-icon-sz.flag-icon-squared {
  background-image: url("sz.ac7c7abb.svg");
}

.flag-icon-tc {
  background-image: url("tc.d30a08f8.svg");
}

.flag-icon-tc.flag-icon-squared {
  background-image: url("tc.21db9ee9.svg");
}

.flag-icon-td {
  background-image: url("td.0508c665.svg");
}

.flag-icon-td.flag-icon-squared {
  background-image: url("td.84b09503.svg");
}

.flag-icon-tf {
  background-image: url("tf.6ef272ca.svg");
}

.flag-icon-tf.flag-icon-squared {
  background-image: url("tf.99b45bf4.svg");
}

.flag-icon-tg {
  background-image: url("tg.7a816152.svg");
}

.flag-icon-tg.flag-icon-squared {
  background-image: url("tg.50694dbf.svg");
}

.flag-icon-th {
  background-image: url("th.3e2b9c0d.svg");
}

.flag-icon-th.flag-icon-squared {
  background-image: url("th.5d57f1cb.svg");
}

.flag-icon-tj {
  background-image: url("tj.abe09558.svg");
}

.flag-icon-tj.flag-icon-squared {
  background-image: url("tj.e9de4f38.svg");
}

.flag-icon-tk {
  background-image: url("tk.f3301c07.svg");
}

.flag-icon-tk.flag-icon-squared {
  background-image: url("tk.cd68247f.svg");
}

.flag-icon-tl {
  background-image: url("tl.ac91f266.svg");
}

.flag-icon-tl.flag-icon-squared {
  background-image: url("tl.597bfea9.svg");
}

.flag-icon-tm {
  background-image: url("tm.6c2afdea.svg");
}

.flag-icon-tm.flag-icon-squared {
  background-image: url("tm.eb424aeb.svg");
}

.flag-icon-tn {
  background-image: url("tn.392283d1.svg");
}

.flag-icon-tn.flag-icon-squared {
  background-image: url("tn.6538beb0.svg");
}

.flag-icon-to {
  background-image: url("to.91725dc3.svg");
}

.flag-icon-to.flag-icon-squared {
  background-image: url("to.f275819b.svg");
}

.flag-icon-tr {
  background-image: url("tr.f57d2046.svg");
}

.flag-icon-tr.flag-icon-squared {
  background-image: url("tr.36094c8c.svg");
}

.flag-icon-tt {
  background-image: url("tt.89699c3f.svg");
}

.flag-icon-tt.flag-icon-squared {
  background-image: url("tt.af5a5ca6.svg");
}

.flag-icon-tv {
  background-image: url("tv.de113a56.svg");
}

.flag-icon-tv.flag-icon-squared {
  background-image: url("tv.fc0ea321.svg");
}

.flag-icon-tw {
  background-image: url("tw.9d077814.svg");
}

.flag-icon-tw.flag-icon-squared {
  background-image: url("tw.7988f5f8.svg");
}

.flag-icon-tz {
  background-image: url("tz.b264d621.svg");
}

.flag-icon-tz.flag-icon-squared {
  background-image: url("tz.89ae7296.svg");
}

.flag-icon-ua {
  background-image: url("ua.40273142.svg");
}

.flag-icon-ua.flag-icon-squared {
  background-image: url("ua.559d7cb0.svg");
}

.flag-icon-ug {
  background-image: url("ug.e05e50e0.svg");
}

.flag-icon-ug.flag-icon-squared {
  background-image: url("ug.32f8f0aa.svg");
}

.flag-icon-um {
  background-image: url("um.16d83bb9.svg");
}

.flag-icon-um.flag-icon-squared {
  background-image: url("um.1d95caac.svg");
}

.flag-icon-us {
  background-image: url("us.bf43c4af.svg");
}

.flag-icon-us.flag-icon-squared {
  background-image: url("us.7d17bfe2.svg");
}

.flag-icon-uy {
  background-image: url("uy.4d20ac53.svg");
}

.flag-icon-uy.flag-icon-squared {
  background-image: url("uy.12027622.svg");
}

.flag-icon-uz {
  background-image: url("uz.336e788b.svg");
}

.flag-icon-uz.flag-icon-squared {
  background-image: url("uz.135d951d.svg");
}

.flag-icon-va {
  background-image: url("va.c7679037.svg");
}

.flag-icon-va.flag-icon-squared {
  background-image: url("va.896095df.svg");
}

.flag-icon-vc {
  background-image: url("vc.c0b13305.svg");
}

.flag-icon-vc.flag-icon-squared {
  background-image: url("vc.2212aa26.svg");
}

.flag-icon-ve {
  background-image: url("ve.919f790a.svg");
}

.flag-icon-ve.flag-icon-squared {
  background-image: url("ve.11017738.svg");
}

.flag-icon-vg {
  background-image: url("vg.86acca03.svg");
}

.flag-icon-vg.flag-icon-squared {
  background-image: url("vg.b18d8dfc.svg");
}

.flag-icon-vi {
  background-image: url("vi.9a2c811f.svg");
}

.flag-icon-vi.flag-icon-squared {
  background-image: url("vi.7bd730ec.svg");
}

.flag-icon-vn {
  background-image: url("vn.cf7ea38e.svg");
}

.flag-icon-vn.flag-icon-squared {
  background-image: url("vn.f7694716.svg");
}

.flag-icon-vu {
  background-image: url("vu.6de454f5.svg");
}

.flag-icon-vu.flag-icon-squared {
  background-image: url("vu.412899ce.svg");
}

.flag-icon-wf {
  background-image: url("wf.2ee8401c.svg");
}

.flag-icon-wf.flag-icon-squared {
  background-image: url("wf.5d38dbd6.svg");
}

.flag-icon-ws {
  background-image: url("ws.b7deb988.svg");
}

.flag-icon-ws.flag-icon-squared {
  background-image: url("ws.b160c61b.svg");
}

.flag-icon-ye {
  background-image: url("ye.b5abbb56.svg");
}

.flag-icon-ye.flag-icon-squared {
  background-image: url("ye.e454bed8.svg");
}

.flag-icon-yt {
  background-image: url("yt.e046bb6f.svg");
}

.flag-icon-yt.flag-icon-squared {
  background-image: url("yt.39dc8a5c.svg");
}

.flag-icon-za {
  background-image: url("za.f8955688.svg");
}

.flag-icon-za.flag-icon-squared {
  background-image: url("za.01d13802.svg");
}

.flag-icon-zm {
  background-image: url("zm.65a6d472.svg");
}

.flag-icon-zm.flag-icon-squared {
  background-image: url("zm.fd0afb4f.svg");
}

.flag-icon-zw {
  background-image: url("zw.18a0c461.svg");
}

.flag-icon-zw.flag-icon-squared {
  background-image: url("zw.dbd8e4c2.svg");
}

.flag-icon-ac {
  background-image: url("ac.0fffdcbd.svg");
}

.flag-icon-ac.flag-icon-squared {
  background-image: url("ac.9d736169.svg");
}

.flag-icon-cp {
  background-image: url("cp.d68265d7.svg");
}

.flag-icon-cp.flag-icon-squared {
  background-image: url("cp.10d3eb8e.svg");
}

.flag-icon-dg {
  background-image: url("dg.547da875.svg");
}

.flag-icon-dg.flag-icon-squared {
  background-image: url("dg.2b602e7f.svg");
}

.flag-icon-ea {
  background-image: url("ea.4c81a6e7.svg");
}

.flag-icon-ea.flag-icon-squared {
  background-image: url("ea.ac60b55e.svg");
}

.flag-icon-es-ct {
  background-image: url("es-ct.e8cd265b.svg");
}

.flag-icon-es-ct.flag-icon-squared {
  background-image: url("es-ct.634a0486.svg");
}

.flag-icon-es-ga {
  background-image: url("es-ga.d1ec9fe6.svg");
}

.flag-icon-es-ga.flag-icon-squared {
  background-image: url("es-ga.6e153f01.svg");
}

.flag-icon-eu {
  background-image: url("eu.79acbb53.svg");
}

.flag-icon-eu.flag-icon-squared {
  background-image: url("eu.31bf5908.svg");
}

.flag-icon-gb-eng {
  background-image: url("gb-eng.2349287d.svg");
}

.flag-icon-gb-eng.flag-icon-squared {
  background-image: url("gb-eng.6260754a.svg");
}

.flag-icon-gb-nir {
  background-image: url("gb-nir.f225883b.svg");
}

.flag-icon-gb-nir.flag-icon-squared {
  background-image: url("gb-nir.8023773a.svg");
}

.flag-icon-gb-sct {
  background-image: url("gb-sct.cfb1b770.svg");
}

.flag-icon-gb-sct.flag-icon-squared {
  background-image: url("gb-sct.ae96b2bd.svg");
}

.flag-icon-gb-wls {
  background-image: url("gb-wls.b11b79ee.svg");
}

.flag-icon-gb-wls.flag-icon-squared {
  background-image: url("gb-wls.0cf411b3.svg");
}

.flag-icon-ic {
  background-image: url("ic.ce67c3d1.svg");
}

.flag-icon-ic.flag-icon-squared {
  background-image: url("ic.eccf69a5.svg");
}

.flag-icon-ta {
  background-image: url("ta.8f30025d.svg");
}

.flag-icon-ta.flag-icon-squared {
  background-image: url("ta.b80e0e96.svg");
}

.flag-icon-un {
  background-image: url("un.b3317a5b.svg");
}

.flag-icon-un.flag-icon-squared {
  background-image: url("un.732f6f2c.svg");
}

.flag-icon-xk {
  background-image: url("xk.c9f185b9.svg");
}

.flag-icon-xk.flag-icon-squared {
  background-image: url("xk.003b2433.svg");
}

.flag-icon-xx {
  background-image: url("xx.bae517e0.svg");
}

.flag-icon-xx.flag-icon-squared {
  background-image: url("xx.da21b451.svg");
}

a {
  -webkit-text-decoration: inherit !important;
  text-decoration: inherit !important;
  color: inherit !important;
}

body {
  background: #0d1117 !important;
  font-family: Inter, sans-serif !important;
}

.container-fluid {
  font-family: Inter, sans-serif !important;
}

.color-dark {
  background-color: #161b22 !important;
}

.color-lightdark {
  background-color: #1e252e !important;
}

.title {
  color: #fff;
}

.title h1 {
  font-size: 1.2rem;
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  filter: brightness(1.25);
}

.clickable2 {
  cursor: pointer;
  color: red !important;
}

.clickable2:hover {
  color: #fff;
  background: #1d2229;
}

.clickable3 {
  cursor: pointer;
  color: #ffffff8e !important;
}

.clickable3:hover {
  color: #fff;
  background: #14181d;
  border-radius: 5px;
}

.clickable:active {
  filter: brightness(1.1);
}

.blue-animation {
  background: #58a6ff;
  animation: 2s infinite pulse-blue;
  box-shadow: 0 0 #47c1f1;
}

.red-animation {
  background: #db101b;
  animation: 2s infinite pulse-red;
  box-shadow: 0 0 #ff5252;
}

@keyframes pulse-red {
  0% {
    transform: scale(.95);
    box-shadow: 0 0 #ff5252b3;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px #ff525200;
  }

  100% {
    transform: scale(.95);
    box-shadow: 0 0 #ff525200;
  }
}

@keyframes pulse-blue {
  0% {
    transform: scale(.95);
    box-shadow: 0 0 #47c1f1;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px #ff525200;
  }

  100% {
    transform: scale(.95);
    box-shadow: 0 0 #ff525200;
  }
}

.primary-animation {
  background: #0d6efd;
  animation: 2s infinite pulse-primary;
  box-shadow: 0 0 #1874f6;
}

.success-animation {
  background: #198754;
  animation: 2s infinite pulse-success;
  box-shadow: 0 0 #399717;
}

@keyframes pulse-primary {
  0% {
    transform: scale(.95);
    box-shadow: 0 0 #3b8bff;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px #ff525200;
  }

  100% {
    transform: scale(.95);
    box-shadow: 0 0 #ff525200;
  }
}

@keyframes pulse-success {
  0% {
    transform: scale(.95);
    box-shadow: 0 0 #1d7817;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px #ff525200;
  }

  100% {
    transform: scale(.95);
    box-shadow: 0 0 #ff525200;
  }
}

.dark-animation {
  background: #161b22;
  animation: 2s infinite pulse-dark;
  box-shadow: 0 0 #212833;
}

@keyframes pulse-dark {
  0% {
    transform: scale(.95);
    box-shadow: 0 0 #2c3844;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px #ff525200;
  }

  100% {
    transform: scale(.95);
    box-shadow: 0 0 #ff525200;
  }
}

.live-animation {
  background: #ff4256;
  animation: 2s infinite pulse-live;
  box-shadow: 0 0 #ff5252;
}

@keyframes pulse-live {
  0% {
    transform: scale(.95);
    box-shadow: 0 0 #ff5252b3;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px #ff525200;
  }

  100% {
    transform: scale(.95);
    box-shadow: 0 0 #ff525200;
  }
}

.green-animation {
  background: #0d6efd;
  animation: 2s infinite pulse-green;
  box-shadow: 0 0 #1874f6;
}

@keyframes pulse-green {
  0% {
    transform: scale(.95);
    box-shadow: 0 0 #2ecc71;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px #ff525200;
  }

  100% {
    transform: scale(.95);
    box-shadow: 0 0 #ff525200;
  }
}

.text-truncate-2, .text-truncate-3 {
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.text-truncate-3 {
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

@media only screen and (width <= 600px) {
  .eeep-text {
    background-color: #1a3362;
    line-height: 14pt;
    color: #fff !important;
    font-family: Verdana !important;
    font-size: 6pt !important;
  }
}

@media only screen and (width >= 600px) {
  .eeep-text {
    background-color: #1a3362;
    line-height: 14pt;
    color: #fff !important;
    font-family: Verdana !important;
    font-size: 7pt !important;
  }
}

@media only screen and (width >= 768px) {
  .eeep-text {
    background-color: #1a3362;
    line-height: 14pt;
    color: #fff !important;
    font-family: Verdana !important;
    font-size: 8pt !important;
  }
}

@media only screen and (width >= 992px) {
  .eeep-text {
    background-color: #1a3362;
    line-height: 14pt;
    color: #fff !important;
    font-family: Verdana !important;
    font-size: 9.2pt !important;
  }
}

@media only screen and (width >= 1200px) {
  .eeep-text {
    background-color: #1a3362;
    line-height: 14pt;
    color: #fff !important;
    font-family: Verdana !important;
    font-size: 9.2pt !important;
  }
}

/*# sourceMappingURL=index.6f7faadb.css.map */
